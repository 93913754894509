<template>
  <div class="newEnroll--container">
    <company-info @setCompanyNumber="setCompanyNumber"></company-info>

    <div
        v-if="all_configs.advertisement && all_configs.advertisement != '' && diagnosticsStatus.status"
        class="notif-box mrg-t-10 shadow-card"
    >
      <div v-html="all_configs.advertisement"></div>
    </div>

    <div v-if="!diagnosticsStatus.status" class="notif-box danger mrg-t-10">
      {{diagnosticsStatus.text}}
    </div>

    <div v-if="minimalEnrollSumStatus === 1" class="notif-box danger mrg-t-10">
      {{minimalEnrollSumText}}
    </div>

    <done-enroll v-show="checkShow('done')" @repeatEnroll="repeatEnroll"></done-enroll>

    <select-info
      v-show="checkShow('client') || checkShow('time')"
      :services="serviceNames"
      :date="normalizeDate"
      :time="normalizeTime"
      :price="servicesPrice"
      :staff="selectedStaff"
      :duration="servicesDuration"
      :groupParticipantsData="groupParticipantsData"
      :isGroup="isGroupAndNeedLoadInfo"
    ></select-info>

    <div v-show="!checkShow('done')" class="newEnroll--actions-fix newEnroll__actions_fix">
      <div v-if="selected.services.length && checkShow('services')" class="enrolls">
        <div>
          <span><b>Выбранные услуги:</b></span>
          <span v-for="ss in selected.services" :key="ss.service_id +'_' +ss.modification_id">
            {{ss.name}};
          </span>
        </div>
        <div>
          <div @click="resetChildSelect" class="btn btn-outline-info">
            <f-awesome icon="times"></f-awesome> Сбросить
          </div>
        </div>
      </div>
      <div class="actions">
        <div v-show="issetBackUrl" @click="returnToUrl" class="btn btn-outline-danger">
          <f-awesome icon="times"></f-awesome> Закрыть
        </div>
        <div v-show="stepPrevShow" @click="move('prev')" class="btn btn-outline-info">
          <f-awesome icon="arrow-left"></f-awesome> Шаг назад
        </div>
        <div
            v-show="!checkShow('mode') && !checkShow('client') && diagnosticsStatus.status"
            @click="move('next')"
            class="btn btn-info pulse"
        >
          Продолжить
        </div>
      </div>
    </div>

    <prepay-block v-if="diagnosticsStatus.status"></prepay-block>

    <div v-if="checkShow('mode') && diagnosticsStatus.status" class="newEnroll--select-mode shadow-card">
      <div @click="setMode('service')" class="item">
        <div>
          <f-awesome icon="list"></f-awesome>
        </div>
        <div>
          <span>Выбрать услугу</span>
        </div>
      </div>
      <div @click="setMode('staff')" class="item">
        <div>
          <f-awesome icon="users"></f-awesome>
        </div>
        <div>
          <span>Выбрать сотрудника</span>
        </div>
      </div>
    </div>

    <div v-show="checkShow('services') && diagnosticsStatus.status" class="newEnroll--select-services">
      <select-services
        :selectReset="resetSelectServices"
        :componentReset="resetComponentService"
        :rerender="checkShow('services')"
        :rerender-force="rerenderForceServices"
        :staff="selectedStaffObject"
        @next="move('next')"
        @selectServices="setServices"
        @selectServicesAndNext="setServicesAndNext"
        @setDiagnostic="setDiagnostic"
      ></select-services>
    </div>

    <div v-show="checkShow('staff')" class="newEnroll--select-staff">
      <select-staff
        @selectStaff="setStaff"
        @next="move('next')"
        @selectStaffAndNext="setStaffAndNext"
        @setDiagnostic="setDiagnostic"
        :rerender="checkShow('staff')"
        :rerender-force="rerenderForceStaff"
        :selectReset="resetSelectStaff"
        :componentReset="resetComponentStaff"
        :services="selected.services"
      ></select-staff>
    </div>

    <div v-show="checkShow('time')" class="newEnroll--select-time">
      <select-calendar-and-interval
        @selectDate="setDate"
        @selectTime="setTime"
        :reset="resetSelectTime"
        :staff="selectedStaffId"
        :duration="servicesDurationMinute"
        :selected-services="selected.services"
        :rerender="resetComponentTime"
      ></select-calendar-and-interval>
    </div>

    <div v-show="checkShow('client')" class="newEnroll--select-client">
      <select-client
        :show="checkShow('client')"
        :isGroup="isGroupAndNeedLoadInfo"
        :reset="resetComponentClient"
        :groupParticipantsData="groupParticipantsData"
        @createEnroll="createNewEnroll"
      ></select-client>
    </div>

    <!-- <set-manifest :short_name="manifestShortName"></set-manifest> -->

    <call-block
        v-if="diagnosticsStatus.status"
        :number="companyNumber"
    ></call-block>

    <cookie-confirm></cookie-confirm>

  </div>
</template>

<script>
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

import SelectServices from "@/components/SelectServices";
import SelectStaff from "@/components/SelectStaff";
import SelectCalendarAndInterval from "@/components/SelectCalendarAndInterval";
import SelectClient from "@/components/SelectClient";
import axios from "axios";
import CompanyInfo from "@/components/CompanyInfo";
import SelectInfo from "@/components/SelectInfo";
import DoneEnroll from "@/components/DoneEnroll";
import CallBlock from "@/components/CallBlock";
import {mapState} from 'vuex';
import PrepayBlock from "@/components/PrepayBlock";
import scrollTo from "@/components/mixins/scrollTo";
import SetManifest from './SetManifest.vue';
import CookieConfirm from './CookieConfirm.vue';
// import Breadcrumbs from "../../../Breadcrumbs";

export default {
  name: "NewEnroll",
  components: {
    PrepayBlock,
    CallBlock,
    DoneEnroll,
    SelectInfo,
    CompanyInfo,
    SelectClient,
    SelectCalendarAndInterval,
    SelectStaff,
    SelectServices,
    SetManifest,
    CookieConfirm
  },
  mixins: [scrollTo],
  data() {
    return {
      manifestShortName: '',
      clientWidth: document.documentElement.clientWidth,
      mode: null,
      getParams: this.$route.query,
      routeParams: this.$route.params,
      backLink: this.$route.query.back_link,
      mapping: [
        {
          block: "mode",
          active: false
        },
        {
          block: "services",
          active: false
        },
        {
          block: "staff",
          active: false
        },
        {
          block: "time",
          active: false
        },
        {
          block: "client",
          active: false
        },
        {
          block: "done",
          active: false
        }
      ],
      mappingServices: ["mode", "services", "staff", "time", "client"],
      mappingStaff: ["mode", "staff", "services", "time", "client"],
      selected: {
        services: [],
        staff: null,
        date: "",
        time: ""
      },
      groupParticipantsData: {},
      resetSelectServices: 0, // счетчик для сброса компонента выбора услуг
      resetSelectStaff: 0, // счетчик для сброса компонента выбора сотрудника
      resetComponentService: 0,
      resetComponentStaff: 0,
      resetComponentTime: 0,
      resetSelectTime: 0,
      resetComponentClient: 0,
      rerenderForceServices: 0,
      rerenderForceStaff: 0,
      blockModePage: false, // Блокировка экрана с выбором выбора :)))
      companyNumber: '',
      diagnosticsStatus: {
        status: true,
        text: ''
      }
    };
  },
  props: {
    reset: Number,
    modeComponent: String,
  },
  watch: {
    reset: function() {
      this.resetComponent();
    },
    modeComponent: function(val){
      this.moveToByMode(val);
    },
    isGroupAndNeedLoadInfo: function(val){
      if(val === true){
        this.loadGroupParticipants();
      }
    }
  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
    issetBackUrl(){
      let result = false;
      if(typeof this.getParams.back_url !== 'undefined'){
        result = true;
      }
      return result;
    },
    stepPrevShow(){
      let result = true;
      if(this.checkShow('mode') && !this.backLink){
        result = false;
      }
      if(this.modeComponent == 'staff' && this.checkShow('staff') && !this.backLink){
        result = false;
      }
      if(this.modeComponent == 'services' && this.checkShow('services') && !this.backLink){
        result = false;
      }
      return result;
    },
    isGroupAndNeedLoadInfo(){
      let result = false;
      let isGroup = false;
      if(this.selected.services.length > 0){
        for(let key in this.selected.services){
          if(+this.selected.services[key].is_group === 1){
            isGroup = true;
          }
        }
        if(isGroup){
          if(
            this.selected.staff !== null && 
            this.selected.date !== "" && 
            this.selected.time !== ""
          ){
            result = true;
          }
        }
      }
      return result;
    },
    serviceNames() {
      let result = [];
      for (let key in this.selected.services) {
        result[result.length] = this.selected.services[key].name;
      }
      return result.join("; ");
    },
    servicesDuration() {
      let duration = 0;
      for (let key in this.selected.services) {
        duration += formatter.hourMinuteToMinute(
          this.selected.services[key].time_hour,
          this.selected.services[key].time_minute
        );
      }
      return formatter.minuteToTime(duration);
    },
    servicesDurationMinute() {
      let duration = 0;
      for (let key in this.selected.services) {
        duration += formatter.hourMinuteToMinute(
          this.selected.services[key].time_hour,
          this.selected.services[key].time_minute
        );
      }
      return duration;
    },
    servicesPrice() {
      let price = 0;
      for (let key in this.selected.services) {
        price += +this.selected.services[key].price;
      }
      return price;
    },
    // 1 - Минимальная сумма не набрана, показать уведомление. 0 - все ОК, можно продолжать запись
    minimalEnrollSumStatus(){
      let result = 0;
      if( 
        this.all_configs && 
        +this.all_configs.use_minimal_enroll_sum === 1 && 
        this.servicesPrice < +this.all_configs.minimal_enroll_sum &&
        this.servicesPrice > 0
      ){
        result = 1;
      }
      return result;
    },
    minimalEnrollSumText(){
      let result = '';
      if( 
        this.all_configs && 
        +this.all_configs.use_minimal_enroll_sum === 1 && 
        this.servicesPrice < +this.all_configs.minimal_enroll_sum
      ){
        result = 'Минимальная сумма выбранных услуг должна быть не менее ' +this.all_configs.minimal_enroll_sum;
      }
      return result;
    },
    selectedStaff() {
      let name = "";
      if (this.selected.staff !== null) {
        name = this.selected.staff.name;
      }
      return name;
    },
    selectedStaffId() {
      let id = 0;
      if (this.selected.staff !== null) {
        id = this.selected.staff.id;
      }
      return +id;
    },
    selectedStaffObject() {
      let result = {};
      if (this.selected.staff !== null) {
        result = this.selected.staff;
      }
      return result;
    },
    normalizeDate() {
      let result = "";
      if (this.selected.date != "") {
        result = formatter.phpDateToString(this.selected.date);
      }
      return result;
    },
    normalizeTime() {
      let result = "";
      if (this.selected.time !== "") {
        result = formatter.minuteToTime(this.selected.time);
      }
      return result;
    }
  },
  methods: {
    loadGroupParticipants(){
      let formData = new FormData();
      formData.append('service[service_id]', this.selected.services[0].service_id);
      formData.append('service[modification_id]', this.selected.services[0].modification_id);
      formData.append('staff', this.selected.staff.id);
      formData.append('date', this.selected.date);
      formData.append('time', this.selected.time);
      formData.append("company", this.routeParams.company);

      axios
        .post(`/online/get-group-participants`, formData)
        .then(resp => {
          this.groupParticipantsData = resp.data;
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    setDiagnostic(val){
      this.diagnosticsStatus.status = false;
      this.diagnosticsStatus.text = val;
    },
    moveToByMode(mode){
      if(mode == 'mode' || mode == 'staff' || mode == 'services'){
        // Установим блокировку выбора выбора
        if(mode == 'staff' || mode == 'services'){
          this.blockModePage = true;
        }
        if(mode == 'services'){
          this.mode = 'service';
        }else{
          this.mode = mode;
        }
        this.moveTo(mode);
      }
    },
    returnToUrl(){
      location.href = this.getParams.back_url;
    },
    setCompanyNumber(val){
      this.companyNumber = val;
    },
    repeatEnroll(){
      this.selected.staff = null;
      this.resetComponentStaff++;
      if(this.modeComponent == 'staff'){
        this.rerenderForceStaff++;
      }
      this.resetComponentService++;
      if(this.mode == 'service'){
        this.rerenderForceServices++;
      }
      this.moveTo(this.modeComponent);
    },
    setMode(val) {
      this.mode = val;
      this.move("next");
    },
    getNowScreen() {
      let result = "";
      for (let key in this.mapping) {
        if (this.mapping[key].active) {
          result = this.mapping[key].block;
          break;
        }
      }
      return result;
    },
    /**
     *
     * @param action - next\prev
     */
    move(action) {
      let nowScreen = this.getNowScreen();
      let nextScreen = "";

      if (
        nowScreen == "services" &&
        !this.selected.services.length &&
        action == "next"
      ) {
        this.$store.dispatch("addNotification", {
          text: "Выберите услугу",
          time: 3,
          color: "danger"
        });
        return false;
      }

      if(nowScreen == 'services' && +this.minimalEnrollSumStatus === 1){
        this.$store.dispatch("addNotification", {
          text: this.minimalEnrollSumText,
          time: 3,
          color: "danger"
        });
        return false;
      }

      if (
        nowScreen == "staff" &&
        this.selected.staff == null &&
        action == "next"
      ) {
        this.$store.dispatch("addNotification", {
          text: "Пожалуйста, выберите сотрудника",
          time: 3,
          color: "danger"
        });
        return false;
      }
      if (nowScreen == "time" && this.selected.time === "" && action == "next") {
        this.$store.dispatch("addNotification", {
          text: "Не выбрано время записи",
          time: 3,
          color: "danger"
        });
        return false;
      }

      let mapping =
        this.mode == "service" ? this.mappingServices : this.mappingStaff;

      for (let key in mapping) {
        if (mapping[key] == nowScreen) {
          let nKey = 0;
          if (action == "next") {
            nKey = +key + 1;
          } else if (action == "prev") {
            nKey = +key - 1;
          }
          nextScreen = mapping[nKey];
          break;
        }
      }
      if (action == "next" && nextScreen == "time") {
        this.resetComponentTime = this.resetComponentTime + 1;
      }
      
      // Прокрутим страницу вверх
      if(nextScreen == 'client' || nextScreen == 'done'){
        let needBlock = document.getElementsByClassName('newEnroll--container')[0];
        let box = needBlock.getBoundingClientRect();
        let needOffset = box.top + pageYOffset;
        this.scrollToSmoothly(needOffset, 300);
      }
      // Заблокируем выбор
      if(action == 'prev' && nextScreen == 'mode' && this.blockModePage){

        if(this.backLink){
          window.location.href = this.generateLinkCommon(this.backLink);
          return false;
          // this.$router.push(this.generateLinkCommon(this.backLink));
          // this.$router.push({path: '/m/' +this.backLink, query: {back_url: this.getParams.back_url}});
        }else{
          return false;
        }
      }
      if(action == 'prev' && nowScreen == 'mode' && this.backLink){
        window.location.href = this.generateLinkCommon(this.backLink);
        return false;
        // this.$router.push(this.generateLinkCommon(this.backLink));
        // this.$router.push({path: '/m/' +this.backLink, query: {back_url: this.getParams.back_url}});
      }
      // Сбросим компонент, если делаем шаг назад
      if (action == "prev") {
        this.resetChildComponent();
      }

      // Установим цели
      if(action == "next"){
        if(nowScreen == 'services'){
          this.$store.dispatch("addYaMetricTarget", {targetName: 'TARGET_CHOOSE_SERVICE'});
        }
        if(nowScreen == 'staff'){
          this.$store.dispatch("addYaMetricTarget", {targetName: 'TARGET_CHOOSE_STAFF'});
        }
        if(nowScreen == 'time'){
          this.$store.dispatch("addYaMetricTarget", {targetName: 'TARGET_CHOOSE_DATETIME'});
        }
      }

      this.moveTo(nextScreen);
    },
    generateLinkCommon(id){
      let result = '/m/' +id;
      if(this.issetBackUrl){
        result += '?back_url=' +this.getParams.back_url;
      }
      return result;
    },
    moveTo(block) {
      for (let key in this.mapping) {
        if (this.mapping[key].block == block) {
          this.mapping[key].active = true;
        } else {
          this.mapping[key].active = false;
        }
      }
    },
    resetComponent() {
      this.mode = null;
      this.selected.services = [];
      this.selected.staff = null;
      this.selected.date = "";
      this.selected.time = "";
      this.resetComponentService = this.resetComponentService + 1;
      this.resetComponentStaff = this.resetComponentStaff + 1;
      this.resetComponentClient = this.resetComponentClient + 1;
      this.moveTo("mode");
    },
    // Сбросить выбор
    resetChildSelect() {
      let nowScreen = this.getNowScreen();
      if (nowScreen == "services") {
        this.resetSelectServices = this.resetSelectServices + 1;
      }
      if (nowScreen == "staff") {
        this.resetSelectStaff = this.resetSelectStaff + 1;
      }
      if (nowScreen == "time") {
        this.resetSelectTime = this.resetSelectTime + 1;
      }
      if (nowScreen == "client") {
        this.resetComponentClient = this.resetComponentClient + 1;
      }
    },
    // Сброс компонента при шаге назад
    resetChildComponent() {
      let nowScreen = this.getNowScreen();
      if (nowScreen == "services") {
        this.resetComponentService = this.resetComponentService + 1;
      }
      if (nowScreen == "staff") {
        this.resetComponentStaff = this.resetComponentStaff + 1;
      }
      if (nowScreen == "time") {
        this.resetSelectTime = this.resetSelectTime + 1;
      }
      if (nowScreen == "client") {
        this.resetComponentClient = this.resetComponentClient + 1;
      }
    },
    checkShow(block) {
      let result = false;
      for (let key in this.mapping) {
        if (this.mapping[key].block == block) {
          result = this.mapping[key].active;
        }
      }
      return result;
    },
    setServices(val) {
      this.$set(this.selected, "services", val);
    },
    setServicesAndNext(val) {
      if(this.$set(this.selected, "services", val)){
        this.move('next');
      }
    },
    setStaff(val) {
      this.$set(this.selected, "staff", val);
    },
    setStaffAndNext(val) {
      this.$set(this.selected, "staff", val);
      if(this.checkShow('staff')){
        this.move('next');
      }
    },
    setDate(val) {
      this.$set(this.selected, "date", val);
    },
    setTime(val) {
      this.$set(this.selected, "time", val);
    },
    createNewEnroll(val) {
      let formData = new FormData();

      formData.append("politic", val.politic);
      formData.append("staff", this.selectedStaffId);
      formData.append("date", this.selected.date);
      formData.append("time", this.selected.time);
      formData.append("exclude_enroll_old_today", true);
      formData.append("company", this.routeParams.company);
      formData.append("source_client_code", val.source_client_code);
      formData.append("is_group", this.isGroupAndNeedLoadInfo ? 1 : 0);

      for(let servKey in this.selected.services){
        let formKey = 'services[' +servKey +']';
        formData.append(formKey +'[service_id]', this.selected.services[servKey]['service_id']);
        formData.append(formKey +'[modification_id]', this.selected.services[servKey]['modification_id']);
        formData.append(formKey +'[category_name]', this.selected.services[servKey]['category_name']);
        formData.append(formKey +'[name]', this.selected.services[servKey]['name']);
        formData.append(formKey +'[price]', this.selected.services[servKey]['price']);
        formData.append(formKey +'[time_hour]', this.selected.services[servKey]['time_hour']);
        formData.append(formKey +'[time_minute]', this.selected.services[servKey]['time_minute']);
      }

      if(!this.isGroupAndNeedLoadInfo){
          formData.append("remind_before_hour", val.clients[0].remind_before_hour);
        }

      for(let key in val.clients){
        let formKey = 'enroll_clients[' + key +']';
        if(val.clients[key].add_field){
          formData.append(formKey +'[add_field]', val.clients[key].add_field);
        }else{
          formData.append(formKey +'[add_field]', '');
        }

        if(val.clients[key].comment){
          formData.append(formKey +'[comment]', val.clients[key].comment);
        }else{
          formData.append(formKey +'[comment]', '');
        }
          
        formData.append(formKey +'[number]', val.clients[key].number);
        formData.append(formKey +'[surname]', val.clients[key].surname);
        formData.append(formKey +'[name]', val.clients[key].name);
        formData.append(formKey +'[lastname]', val.clients[key].lastname);
        formData.append(formKey +'[email]', val.clients[key].email); 
      }

      this.$store.dispatch("startPreloader");
      
      axios
        .post(`/online/set-enroll`, formData)
        .then(resp => {
          if(resp.data.result == 'ok'){
            this.$store.dispatch("addYaMetricTarget", {targetName: 'TARGET_ENROLL'});
            this.moveTo('done');
          }
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    diagnostics(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company", this.routeParams.company);
      axios
          .post(`/online/diagnostics`, formData)
          .then(resp => {
            if(resp.data.result == 'error'){
              this.diagnosticsStatus.status = false;
              this.diagnosticsStatus.text = resp.data.message;
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.diagnosticsStatus.status = false;
            this.diagnosticsStatus.text = err.response.data.message;
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    this.diagnostics();
    this.moveToByMode(this.modeComponent);

    this.manifestShortName = 'Запись';
  }
};
</script>

<style scoped lang="less">
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
.newEnroll--container{
  position: relative;
  width: inherit;
  padding-bottom: 60px;
  min-height: 75vh;
}
.newEnroll--back-action {
  text-align: left;
  margin-bottom: 8px;

  svg {
    font-size: 23px;
    cursor: pointer;
    color: #dc3545;
  }
}

.newEnroll--actions-fix{
  background-color: #dde7ec;
  border-radius: 4px;

  .enrolls{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;
    position: relative;
    margin-bottom: 20px;
    padding: 16px 15px 8px 15px;

    &>div:nth-of-type(1){
      width: 80%;
    }
    &>div:nth-of-type(2){
      text-align: right;
      width: 140px;
    }
  }
  .enrolls:not(:last-child):after {
    content: "";
    display: block;
    width: 100px;
    height: 1px;
    margin: 0 auto;
    border-top: 1px solid #d0d0d0;
    position: absolute;
    bottom: -10px;
  }
  .actions{
    text-align: center;
    box-sizing: border-box;
    padding: 17px 10px;
  }
}
.newEnroll__actions {
  margin-top: 20px;
  box-shadow: -1px -3px 24px 0px rgba(0,0,0,0.25);
}
.newEnroll__actions_fix{
  width: inherit;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 2px -1px 20px 0px rgba(0,0,0,0.75);
  z-index: 99999999;
}
.newEnroll--selected-params {
  margin-bottom: 10px;
  color: #4f4f4f;
  font-size: 13px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: row;

  & > div {
    width: 50%;

    .item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      flex-direction: row;
      border-bottom: 1px solid #c4c4c4;

      & > div:nth-of-type(1) {
        width: 300px;
        font-weight: 500;
      }
      & > div:nth-of-type(2) {
        width: 100%;
      }
    }
  }
}
.newEnroll--select-mode {
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;

  .item {
    cursor: pointer;
    width: 160px;
    text-align: center;
    margin: 0 10px;

    & > div:nth-of-type(1) {
      font-size: 44px;
      color: #c4c4c4;
    }
    & > div:nth-of-type(2) {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #41a2fc;
      
      span{
        border-bottom: 3px solid #41a2fc;
      }
    }
  }
}

@media (max-width: 400px) {
  .newEnroll--select-mode {
  flex-wrap: wrap;
  padding-top: 30px;

  .item {
    width: 100%;
    margin: 0 10px 30px 10px;
  }
}
}
</style>
