<template>
  <div class="select-info--container shadow-card">
    <div class="title">Данные записи:</div>
    <div class="data mrg-t-20">
      <div>
        <b>Услуга:</b> {{services}}
      </div>
      <div><b>Дата:</b> <span class="text-orange">{{date}}</span></div>
      <div><b>Время:</b> <span class="text-orange">{{time}}</span></div>
      <div><b>Стоимость:</b> <span v-if="price == 0">-</span><span v-if="price > 0">{{price}}</span></div>
      <div><b>Сотрудник:</b> {{staff}}</div>
      <div><b>Продолжительность:</b> {{duration}}</div>
      <div v-if="isGroup && groupParticipantsData.show_participant" class="mrg-t-10">
        <b>Кол-во свободных мест:</b> {{ groupParticipantsData.text }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SelectInfo",
  components: {},
  data() {
    return {

    };
  },
  props: {
    services: String,
    date: String,
    time: String,
    price: Number,
    staff: String,
    duration: String,
    groupParticipantsData: Object,
    isGroup: Boolean
  },
  watch: {

  },
  computed: {

  },
  methods: {

  },
  created() {

  }
};
</script>

<style scoped lang="less">
.select-info--container{
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 0 20px 0;

  .title{
    text-align: center;
    color: #4f4f4f;
    font-size: 16px;
  }
  .data{
    font-size: 14px;
    color: #333;
    padding-left: 40px;
    box-sizing: border-box;
  }
}
</style>
